export const debounce = (fn: any, delay: number) => {
  let timeoutID: any = null;

  return function(...args: any) {
    clearTimeout(timeoutID);

    timeoutID = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
