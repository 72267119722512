
import { Events, OnlineCourses } from "@/api";
import {
  AssessmentProgress,
  CourseProgressItem,
  CourseProgressWithPagination,
  PracticalProgress
} from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { TableOptions } from "@/types";
import { debounce } from "@/utils/debounce";
import { Component, Vue, Watch } from "vue-property-decorator";

type Filter = { delegate: string; passportID: string; course: string };

@Component({
  components: { PageTitle },
  methods: { debounce }
})
export default class CourseProgressionPage extends Vue {
  loading = false;
  headersTitle = [{ icon: "mdi-chart-pie", header: "Course Progress" }];
  filters: Filter = { delegate: "", passportID: "", course: "" };
  page = 1;
  limit = 10;
  options: TableOptions = {} as TableOptions;
  courses: any[] = [];
  progressItems: CourseProgressItem[] = [];
  progressItemsWithPagination: CourseProgressWithPagination = {
    meta: {
      perPage: this.limit
    }
  };
  debounce: any;

  headers = [
    {
      text: "Delegate Name",
      value: "name"
    },
    {
      text: "Passport ID",
      value: "delegatePassport"
    },
    {
      text: "Course Name",
      value: "courseName"
    },
    {
      text: "Start Date",
      value: "eventStartDate"
    },
    // {
    //   text: "Course’s Assessment Name",
    //   value: "name"
    // },
    {
      text: "Formative",
      value: "formative",
      align: "center"
    },
    {
      text: "Summative",
      value: "summative",
      align: "center"
    },
    {
      text: "Practical",
      value: "practical",
      align: "center"
    },
    {
      text: "Essay",
      value: "essay",
      align: "center"
    }
  ];

  chartOptions(item: any, practical = false) {
    return {
      chart: {
        type: "pie",
        toolbar: {
          show: false
        }
      },
      labels: practical
        ? ["Uploaded", "Pending", "Skipped"]
        : ["Completed", "Pending", "Skipped"],
      legend: {
        show: false,
        position: "bottom"
      },
      colors: ["#008000", "#808080", "#ff0000"],
      dataLabels: {
        enabled: false
      },
      tooltip: {
        custom: (val: any) => {
          switch (val.seriesIndex) {
            case 0:
              return practical
                ? `Uploaded ${item?.uploaded?.value || 0} - ${item?.uploaded
                    ?.percentage || 0}%`
                : `Completed ${item?.completed?.value || 0} - ${item?.completed
                    ?.percentage || 0}%`;
            case 1:
              return practical
                ? `Pending ${item?.pendingUploaded?.value || 0} - ${item
                    ?.pendingUploaded?.percentage || 0}%`
                : `Pending ${item?.pending?.value || 0} - ${item?.pending
                    ?.percentage || 0}%`;
            case 2:
              return `Skipped ${item?.skipped?.value || 0} - ${item?.skipped
                ?.percentage || 0}%`;

            default:
              break;
          }
        }
        // y: {
        //   formatter: (seriesName: string) => {
        //     console.log("seriesName: ", seriesName);

        //     return seriesName + "%";
        //   }
        // }
      },
      stroke: {
        width: 1
      }
    };
  }

  formativeSeries(item: AssessmentProgress) {
    return [
      item?.completed?.percentage || 0,
      item?.pending?.percentage || 0,
      item?.skipped?.percentage || 0
    ];
  }
  summativeSeries(item: AssessmentProgress) {
    return [
      item?.completed?.percentage || 0,
      item?.pending?.percentage || 0,
      item?.skipped?.percentage || 0
    ];
  }
  practicalSeries(item: PracticalProgress) {
    return [
      item?.uploaded?.percentage || 0,
      item?.pendingUploaded?.percentage || 0,
      item?.skipped?.percentage || 0
    ];
  }
  essaySeries(item: AssessmentProgress) {
    return [
      item?.completed?.percentage || 0,
      item?.pending?.percentage || 0,
      item?.skipped?.percentage || 0
    ];
  }

  @Watch("options", { deep: true })
  async onOptionsChange(val: any, old: any) {
    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    if (old !== val) await this.loadCourses(val.page);
  }
  @Watch("filters", { deep: true })
  async onChangeFilters() {
    this.loadCourses(this.page);
  }

  async created() {
    try {
      const res = await OnlineCourses.listWithoutPagination();
      this.courses = Array.from(new Set((res || []).map((el: any) => el.name)));
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  handleInput = this.debounce((field: string, filter: keyof Filter) => {
    this.filters[filter] = field;
  }, 500);

  async loadCourses(page = 1) {
    try {
      this.loading = true;
      const coursesWithPagination = await Events.courseProgressList(
        page,
        this.filters,
        this.limit
      );
      this.progressItems = coursesWithPagination.data
        ? coursesWithPagination.data
        : ([] as CourseProgressItem[]);
      this.progressItemsWithPagination = coursesWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors || { err: err.result.message });
    } finally {
      this.loading = false;
    }
  }
}
